@font-face {
  font-family: 'KarbonBold';
  src: url(../fonts/karbon-bold-webfont.eot);
  src: url(../fonts/karbon-bold-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/karbon-bold-webfont.woff) format("woff"), url(../fonts/KarbonApp-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonLight';
  src: url(../fonts/karbon-light-webfont.eot);
  src: url(../fonts/karbon-light-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/karbon-light-webfont.woff) format("woff"), url(../fonts/KarbonApp-Light.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonMedium';
  src: url(../fonts/karbon-medium-webfont.eot);
  src: url(../fonts/karbon-medium-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/karbon-medium-webfont.woff) format("woff"), url(../fonts/KarbonApp-Medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonRegular';
  src: url(../fonts/karbon-regular-webfont.eot);
  src: url(../fonts/karbon-regular-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/karbon-regular-webfont.woff) format("woff"), url(../fonts/KarbonApp-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonSemibold';
  src: url(../fonts/karbon-semibold-webfont.eot);
  src: url(../fonts/karbon-semibold-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/karbon-semibold-webfont.woff) format("woff"), url(../fonts/KarbonApp-Semibold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'KarbonThin';
  src: url(../fonts/karbon-thin-webfont.eot);
  src: url(../fonts/karbon-thin-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/karbon-thin-webfont.woff) format("woff"), url(../fonts/KarbonApp-Thin.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "nexi";
  src: url(../fonts/nexi.eot);
  src: url(../fonts/nexi.eot?#iefix) format("embedded-opentype"), url(../fonts/nexi.woff) format("woff"), url(../fonts/nexi.ttf) format("truetype"), url(../fonts/nexi.svg#nexi) format("svg");
  font-weight: normal;
  font-style: normal; }

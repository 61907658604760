.simulator{
  .schema{
    br{
      display:none;
    }
  }
  .container-mobile{
    float:left;
    width: 100%;
    margin: 40px 0 0;
    text-align: center;
    color: $azure;
    padding: 20px 0;
    background: #FFF;
    display: none;
    strong{
      font-size: 28px;
    }

  }
}
@media screen and (max-width:768px){
  .hero{
    background: url("../img/hero-mobile.jpg") center center no-repeat;
    .hero-content{
    padding: 60px 10px;
    width: auto;
      p{
        font-size: 30px;
      }
    }
  }
  .simulator{
    .container-mobile{
      display: block;
      p{
        font-weight: 700;
        margin: 0;
      }
    }
    .commissione{
      padding: 10px 30px;
    }
    .block-values{
      display: none;
    }
    .primo{
      font-size: 28px;
    }
    .secondo{
      font-size: 18px;
    }
    .container {
       .wrap{
         .col{
           &.col-left{
             width: 20%;
             a.slider-button{
               margin-left: 10px;
             }
           }
           &.col-center{
             width: 60%;
           }
           &.col-right{
             width: 20%;
             a.slider-button{
               margin-right: 10px;
             }
           }
         }
       }
     }
  .schema{
      br{
        display:inherit;
      }
      .li-1{
        text-align: left;
      }

      &.schema-2{
        .li-2{
          text-align: right;
        }
      }
      &.schema-3 {
        .li-3 {
          text-align: right;
        }
      }
        &.schema-4{
          .li-4{
            text-align: right;
          }
        }
        &.schema-5{
          .li-5{
            text-align: right;
          }
        }
        &.schema-6{
          .li-6{
            text-align: right;
          }
        }
    }
  }
  .imput-plafond{
    width: 100%;
    max-width: 100%;
    p{
      width: 100%;
      text-align: center;
    }
    .box-plafond{
      float: none !important;
      margin: 0 auto;
      display: block;
      clear: both;
      width: 150px;
      margin-top:0;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width:1840px){
  .hero{
      background-size:100% auto;
     }
}

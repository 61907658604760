@import "jquery-ui";
@import "font";
@import "function";

$blue: #0A9Ad2;
$azure: #2A30A0;

body {
  font-family: 'KarbonRegular';
  padding: 0;
  margin: 0;
}
.hero{
  float: left;
  width: 100%;
  background: url("../img/hero-desk.jpg") center center no-repeat;
  background-size: cover;
  color:#FFF;
  .hero-content{
    float: none;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    padding: 175px 0;
    p{
      padding:0;
      margin: 10px 0 0;
      font-size: 40px;
    }
  }
}
.simulator{
  color:#FFF;
  .left{
    float:left;
  }
  .right{
    float:right;
  }
  .schema{
    display: none;
    &.schema-1{
      .li-1,.li-2,.li-3,.li-4,.li-5,.li-6{
        width: 16.666666666666667%;
      }
      display: block;
    }
    &.schema-2{
      .li-1,.li-2,.li-3,.li-4,.li-5,.li-6{
        width: 50%;
      }
      .li-2{
        float:right;
        b{
          float:right;
        }
      }
    }
    &.schema-3{
      .li-1,.li-2,.li-3,.li-4,.li-5,.li-6{
        width: 33.333333333333333%;
      }
      .li-2 b{
        position: absolute;
        left: 20px;
        right: 0;
        margin: auto;
      }
      .li-3{
        float:right;
        b{
          float:right;
        }
      }
    }
    &.schema-4{
      position: relative;
      .li-1,.li-2,.li-3,.li-4,.li-5,.li-6{
        width: 33.333333333333333%;
      }
      .li-2,.li-3{
        b{
          left: -15px;
          right: 0;
          margin: auto;
          position: relative;
        }
      }
      .li-4{
        float:right;
        position: absolute;
        top:0;
        right: 0;
        b{
          float:right;
        }
      }
    }
    &.schema-5{
      position: relative;
      .li-1,.li-2,.li-3,.li-4,.li-5,.li-6{
        width: 25%;
      }
      .li-2,.li-3,.li-4{
        b{
          left: -20px;
          right: 0;
          margin: auto;
          position: relative;
        }
      }
      .li-5{
        float:right;
        position: absolute;
        top:0;
        right: 0;
        b{
          float:right;
        }
      }
    }
    &.schema-6{
      position: relative;
      .li-1,.li-2,.li-3,.li-4,.li-5,.li-6{
        width: 20%;
      }
      .li-2,.li-3,.li-4,.li-5{
        b{
          left: -20px;
          right: 0;
          margin: auto;
          position: relative;
        }
      }
      .li-6{
        float:right;
        position: absolute;
        top:0;
        right: 0;
        b{
          float:right;
        }
      }
    }
  }
  .ui-widget.ui-widget-content{
    border: none;
    -webkit-border-radius:0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .ui-slider-range-min{
    -webkit-border-radius:0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .commissione{
    text-align: center;
    padding: 160px 30px 30px;

    p{
      font-size: 12px;
      a{
        color: #FFF;
        text-decoration: none;
        font-family: KarbonBold;
        &:hover{
          text-decoration: underline;
          }
        }
    }
    span{
      font-weight: bold;
      font-family: KarbonBold;
      font-size: 18px;
    }
    .totale-plafond{
      font-size: 16px;
    }
  }
  font-family: "KarbonRegular";
  font-weight: 500;
  text-size-adjust:100%;
  word-wrap: break-word;
  background: #2A30A0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  float:left;
  width: 100%;
  color: white;
  .primo,.secondo,.terzo{
    text-align: center;
    float:left;
    width: 100%;
  }
  .primo{
    font-size: 30px;
    @include my-linear-gradient($blue,$azure);
    margin-top: 0;
    padding: 0;
    position: relative;
    a.back{
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 18px;
      color:#FFF;
      text-decoration: none;
    }
    span{
      padding: 30px 10px 10px 10px;
      float: none;
      margin: 0 auto;
      display: block;
    }
    small{
      display: block;
      padding: 10px;
      font-size: 15px;
    }
  }
  .secondo{
   font-size: 25px;
   margin: 30px 0 10px;
 }
 .terzo{
  font-size: 25px;
  margin: 50px 0 30px;
  }
  .quarto{
    text-align: center;
    width: 100%;
    float: left;
  }
 .import-setting{
   padding-bottom: 20px;
   font-size: 25px;
 }
 .valor{
   margin-left:30%;float:left;
    span{
      color:white;
      font-size: 25px;
    }
    input{
      border:0; color:white;margin-left:36%;background-color:$blue;font-size:16px;
    }
  }
  .box {
   float:left;
   > div{
      margin: 0 auto;
      padding: 0 0 10px;
      float: left;
      width: 100%;
      list-style-type: none;
     div{
       display: block;
       vertical-align: top;
       float:left;
       text-align: center;
      position: relative;
       b{
         float: left;
         width: 50px;
       }
     }
   }
 }
 .ui-slider-range {
   @include my-linear-gradient($blue,$azure);
 }
 .ui-slider-handle { border-color: none; outline: none !important; }
 .richiedi {
   padding: 20px 0;
   font-size: 18px;
    input {
      float:none;
      margin: 0 auto;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      background: #FFF;
      color:$azure;
      width: 310px;
      height: 60px;
      border: none;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
  }
 }
.container {
   float:left;
   width: 100%;
   z-index: 100000;
   position: relative;
   margin-top: 10px;
   .wrap{
     float: none;
     max-width: 800px;
    // width: 100%;
     margin: 0 auto;
     .col{
       min-height: 20px;
       &.col-left{
         float: left;
         width: 10%;
         a{
           float: left;
         }
       }
       &.col-center{
         float:left;
         width: 80%;
         padding-top:10px;
       }
       &.col-right{
         float:right;
         width: 10%;
         a{
           float: right;
         }
       }
       .slider-button{
         display: block;
         color:$blue;
         background-color: white;
         width: 30px;
         height: 30px;
         font-size: 35px;
         line-height: 28px;
         text-align: center;
         font-weight: bold;
         text-decoration: none;
       }
     }
   }
   #importo-handle{
     text-align: center;
     color:#FFF;
     line-height: 100px;
     span{
       position: absolute;
       left: -25px;
       right: -25px;
       margin: 0 auto;
       width: 100px;
       top: 10px;
       font-weight: bold;
     }

   }
   .block-values{
     width: 360px;
     position: absolute;
     top: 50px;
     left: -160px;
     color:$azure;
     .block-arrow{
       width: 0;
       height: 0;
       border-left: 20px solid transparent;
       border-right: 20px solid transparent;
       border-bottom: 20px solid #FFF;
       float:none;
       margin: 0 auto;
     
     }
     .block-text{
       width: 360px;
       background: #FFF;
       text-align: center;
       padding: 10px 0;
       strong{
         padding: 10px 0 4px;
         float: left;
         width: 100%;
         font-size:28px;
       }
       p{
         padding: 4px 0;
         margin: 0;
       }
       .commissione-totale,
       .commissione-mensile{
          font-weight: bold;
          font-family: KarbonBold;
      }
      .commissione-mensile{
        font-size: 19px;
        padding-right: 2px;
      }
      .commissione-totale{
        font-size: 14px;
        padding-right: 2px;
      }

      .total{
        font-size: 11px !important;
      }
     }
   }
 }
}
.block-calculator{
  position: relative;
  float: left;
  .block-error{
    float: left;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    background-color: #2a30a0;
    z-index: 1000000;
    position: absolute;
    display: none;
    opacity: 0.7;
    &.show{
      display: block;
    }
  }
  .block-disallow{
    background: #2a30a0;
    width: 100%;
    min-height: 100px;
    position: absolute;
    z-index: 10000000;
    top: 0;
    bottom: 0;
    opacity: 0.4;
  }
}
.block-plafond{
  width: 100%;
  float: left;
  .center{
    float: none;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    max-width: 620px;
    .error{
      float: left;
      text-align: center;
      width: 100%;
      padding: 10px 0;
      opacity: 0;
      &.show{
        opacity: 1;
      }
    }
    .block{
      float: left;
      width: 100%;
      padding-top: 50px;
      display: table;
      .text{
        float: none;
        display: inline-block;
        p{
          text-align: right;
          padding: 8px 10px;
        }
      }
      .imput-plafond{
        float: none;
        display:block;
        max-width: 480px;
        overflow: hidden;
        margin: 0 auto;
        p{
          padding:0 10px;
          float: left;
          font-size: 20px;
        }
        .box-plafond{
          border: 1px solid #FFF;
          border-radius: 8px;
          float: left;
          padding: 2px 10px 2px;
          margin-top: 5px;
          font-size: 20px;
          input{
            border:none;
            background: $azure;
            width: 130px;
            color:#FFF;
            height: 50px;
            line-height: 30px;
            text-align: right;
            outline: none !important;
            font-size: 20px;
          }
        }
      }
    }
  }
}
@import "mobile";

@mixin my-linear-gradient($colorFist, $colorSecond) {
  background-image: -ms-linear-gradient(top righ-webkit-gradient $colorFist  0%, $colorSecond 100%);
  background-image: -moz-linear-gradient(top right, $colorFist  0%, $colorSecond 100%);
  background-image: -o-linear-gradient(top right, $colorFist  0%, $colorSecond 100%);
  background-image: -webkit-gradient(top right, $colorFist  0%, $colorSecond 100%);
  background-image: -webkit-linear-gradient(top right, $colorFist  0%, $colorSecond 100%);
  background-image: linear-gradient(top right, $colorFist  0%, $colorSecond 100%);
}

// @mixin my-border-radius($pixel) {
//   -webkit-border-radius: $pixel px;
//   -moz-border-radius: $pixel px;
//   border-radius: $pixel px;
// }
